<template>
  <div>
    <list-review-add-new
      :is-add-new-review-sidebar-active.sync="isAddNewReviewSidebarActive"
      @refetch-data="fetchData"
    />

    <b-row>

      <b-col
        cols="3"
        class="mb-1"
      >
        <b-form-group
          label="Ребёнок"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="childNameFilter"
              type="search"
              placeholder="Имя"
              @input="filterData"
            />
            <b-input-group-append>
              <b-button
                :disabled="!childNameFilter"
                @click="clearFilter"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <hr>
        <b-form-group
          label="Работник"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="workerFilter"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="workerFilter"
              v-model="workerFilter"
              type="search"
              placeholder="Имя пользователя"
              @input="filterData"
            />
            <b-input-group-append>
              <b-button
                :disabled="!workerFilter"
                @click="clearWorkerFilter"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        cols="3"
        class="mb-1 border-left border-right"
      >
        <b-form-group
          label="После"
          label-size="sm"
          label-for="dateFilter0"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-datepicker
              id="dateFilter0"
              v-model="dateFilter[0]"
              locale="ru"
              @input="filterData"
            />
            <b-input-group-append>
              <b-button
                :disabled="!dateFilter[0]"
                @click="clearDateFilter(0)"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="До"
          label-size="sm"
          label-for="dateFilter1"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-datepicker
              id="dateFilter1"
              v-model="dateFilter[1]"
              locale="ru"
              @input="filterData"
            />
            <b-input-group-append>
              <b-button
                :disabled="!dateFilter[1]"
                @click="clearDateFilter(1)"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        v-if="$can('create', 'reviews')"
        cols="2"
        class="mb-1"
      >
        <b-button
          variant="primary"
          @click="isAddNewReviewSidebarActive = true"
        >
          <span class="text-nowrap">Добавить комментарий</span>
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-table
          :fields="fields"
          :items="items"
          responsive
          show-empty
          empty-text="Нет материалов"
          @row-selected="onRowSelected"
        >
          <template #cell(actions)="row">
            <b-icon-pencil-fill
              class="cursor-pointer"
              @click="editSidebar(row.item)"
            />
          </template>

        </b-table>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          class="mt-1"
          align="right"
          :total-rows="totalCount"
          :per-page="limit"
          @change="pageChanged"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination, BRow,
  BTable, BIconPencilFill
} from "bootstrap-vue"
import ListReviewAddNew from './ListReviewAddNew.vue'

export default {
  components: {
    BButton,
    BLink,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroupAppend,
    BPagination,
    BRow,
    BCol,
    ListReviewAddNew,
    BIconPencilFill
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'Номер'
        },
        {
          key: 'child_name',
          label: 'ФИО Ребенка'
        },
        // {
        //   key: 'birth_date',
        //   label: 'День рождения'
        // },
        {
          key: 'section_name',
          label: 'Секция'
        },
        {
          key: 'description',
          label: 'Комментарий'
        },
        {
          key: 'worker_name',
          label: 'Работник'
        },
        {
          key: 'date',
          label: 'Дата'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ],
      isAddNewReviewSidebarActive: false,
      childNameFilter: '',
      workerFilter: '',
      dateFilter: [], // [lower, upper]
      selected: [],
      currentPage: 1,
      limit: 100,
    }
  },
  computed: {
    items() {
      return this.$store.state.info.reviews
    },
    totalCount() {
      return this.$store.state.info.childrenCount
    },
  },
  created() {
    this.fetchData(1)
  },
  methods: {
    editSidebar(review) {
      this.$store.commit('info/setReviewToEdit', { ...review })
      this.isAddNewReviewSidebarActive = true
    },
    fetchData(page) {
      // eslint-disable-next-line no-param-reassign
      page = page || 0
      const params = {
        limit: this.limit,
        offset: page === 1 ? 0 : (page * this.limit) - this.limit,
      }
      if (this.childNameFilter !== '') {
        // eslint-disable-next-line dot-notation
        params["child__full_name__icontains"] = this.childNameFilter
      }
      if (this.workerFilter !== '') {
        // eslint-disable-next-line dot-notation
        params["worker__username__icontains"] = this.workerFilter
      }

      const after = this.dateFilter[0]
          // eslint-disable-next-line comma-style
          , befor = this.dateFilter[1]

      if (after) {
        // eslint-disable-next-line dot-notation
        params["date__gte"] = after
      }
      if (befor) {
        // eslint-disable-next-line dot-notation
        params["date__lte"] = befor
      }

      this.$store.dispatch('info/getReviews', params)
    },
    clearFilter() {
      this.filter = ''
      this.filterData()
    },
    clearWorkerFilter() {
      this.workerFilter = ''
      this.filterData()
    },
    clearDateFilter(idx) {
      this.dateFilter[idx] = undefined
      this.filterData()
    },
    filterData() {
      this.fetchData(1)
    },
    pageChanged(page) {
      this.fetchData(page)
    },
    onRowSelected(items) {
      this.$emit('items-selected', items)
    },
  },
}
</script>

<style lang="scss">
.table-custom-active {
  background-color: #161d31;
}
</style>
