<template>
  <b-sidebar
    id="add-new-review-sidebar"
    :visible="isAddNewReviewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-review-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Добавить комментарий
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSidebar"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Ребенок"
            label-for="children"
          >
            <model-select
              v-model="reviewData.child"
              :options="childrenOptions"
              placeholder="Начните набирать"
            />
          </b-form-group>

          <b-form-group
            label="Секция"
            label-for="childrenSection"
          >
            <b-form-select
              id="childrenSection"
              v-model="reviewData.section"
              label="Секция"
              value-field="id"
              text-field="name"
              :options="reviewData.sectionOptions"
            />
          </b-form-group>

          <b-form-group
            label="Дата"
            label-for="datepicker-placeholder"
          >
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="reviewData.date"
              placeholder="Выберите дату"
              rules="required"
            />
          </b-form-group>

          <b-form-textarea
            id="textarea"
            v-model="reviewData.description"
            placeholder="Комментарий..."
            rows="3"
            max-rows="6"
            spellcheck="true"
          />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BFormDatalist,
} from "bootstrap-vue"
import flatPickr from "vue-flatpickr-component"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref, watch } from "@vue/composition-api"
import { required, alphaNum, email } from "@validations"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import store from "@/store"
import Vue from "vue"

import "vue-search-select/dist/VueSearchSelect.css"
import { ModelSelect } from "vue-search-select"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    flatPickr,
    vSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormDatalist,
    ModelSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewReviewSidebarActive",
    event: "update:is-add-new-review-sidebar-active",
  },
  props: {
    isAddNewReviewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      option: [],
      childrenOptions: [],
    }
  },
  created() {
    console.log(this.$store.state.info.user)
    this.$store.dispatch("info/getChildren", { limit: 1000 }).then(() => {
      this.$store.state.info.childrenList.forEach(children => {
        this.childrenOptions.push({
          text: children.full_name,
          value: children.id,
        })
      })
    })
    this.$store.dispatch("info/getSections", { limit: 1000 })
  },
  setup(props, { emit }) {
    const blankReview = {
      description: "",
      child: "",
      worker: "",
      sectionOptions: [],
      section: "",
      date: "",
    }

    const reviewData = ref(JSON.parse(JSON.stringify(blankReview)))
    const resetuserData = () => {
      reviewData.value = JSON.parse(JSON.stringify(blankReview))
    }
    const hideSidebar = () => {
      emit("update:is-add-new-review-sidebar-active", false)
      reviewData.value = JSON.parse(JSON.stringify(blankReview))
    }
    watch(
      () => store.state.info.reviewToEdit,
      review => {
        console.log("editing", review)
        if (review && review.hasOwnProperty("id")) {
          reviewData.value.id = review.id
          reviewData.value.description = review.description
          reviewData.value.child = review.child
          reviewData.value.worker = review.worker
          reviewData.value.section = review.section
          reviewData.value.date = review.date
        }
      }
    )
    watch(
      () => props.isAddNewReviewSidebarActive,
      val => {
        if (val === false) {
          blankReview.value = JSON.parse(JSON.stringify(blankReview))
        }
      }
    )
    watch(
      () => reviewData.value.child,
      childId => {
        const child = store.state.info.childrenList.find(
          ch => ch.id === childId
        )
        console.log("changed", child)
        let childSectionIds = child?.child_section
        if (childSectionIds) {
          childSectionIds = new Set(childSectionIds)
          console.log(childSectionIds)
          const s = store.state.info.sections.filter(
            // eslint-disable-next-line no-shadow
            s => childSectionIds.has(s.id)
          )
          console.log(s)
          reviewData.value.sectionOptions = s
        }
      }
    )

    const onSubmit = () => {
      if (reviewData.value.hasOwnProperty("id")) {
        store
          .dispatch("info/editReviews", reviewData.value)
          .then(() => {
            Vue.$toast.success("Сохранён", { timeout: 2000 })
            emit("refetch-data")
            emit("update:is-add-new-review-sidebar-active", false)
            reviewData.value = blankReview
          })
          .catch(() => {
            Vue.$toast.error("Не удалось сохранить", { timeout: 2000 })
          })
      } else {
        reviewData.value.worker = JSON.parse(localStorage.user).id
        store
          .dispatch("info/addReviews", reviewData.value)
          .then(() => {
            Vue.$toast.success("Сохранён", { timeout: 2000 })
            emit("refetch-data")
            emit("update:is-add-new-review-sidebar-active", false)
            reviewData.value = blankReview
          })
          .catch(() => {
            Vue.$toast.error("Не удалось сохранить", { timeout: 2000 })
          })
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)

    return {
      reviewData,
      onSubmit,
      hideSidebar,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: #7367f0;
}
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: #7367f0;
}
</style>
